@media (max-width: 430px) {
  .popup-title {
    margin: 1em 2em !important;
  }

  .popup-description {
    margin: 1em 2.3em !important;

    width: 80% !important;
  }

  .popupbox {
    width: 395px !important;
  }

  .popup-buttons {
    display: grid !important;

    margin: 2em 5.5em !important;
  }

  .popup-button {
    width: 73% !important;

    background-color: #0e59aa;

    margin: 0em 3em !important;
  }

  .popup-close {
    margin: 6px 46px !important;
  }

  .popup-buttons img {
    width: 26vh !important;

    height: 8vh !important;
  }
}

.popupbox {
  position: absolute;

  top: 50%;

  left: 50%;

  transform: translate(-50%, -50%);

  width: 462px;

  padding: 20px;

  background-color: white;

  border: 1px solid #ccc;

  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  z-index: 1000;
}

.popup-title {
  font-size: 18px;

  font-weight: bold;

  font-family: 'Source Sans 3', sans-serif;
}
.popup-line {
  border: none;
  border-top: 2px solid #ccc;
  margin: 10px -20px;
}

.popup-description {
  margin-top: 10px;

  font-family: 'Source Sans 3', sans-serif;
}

.popup-buttons {
  margin-top: 20px;

  display: flex;

  justify-content: space-between;

  align-items: center;

  margin: 2em 3.5em;
}

.popup-buttons img {
  width: 18vh;

  height: 6.3vh;
}

.popup-button {
  padding: 10px 20px;

  background-color: #0e59aa;

  color: white;

  border: none;

  cursor: pointer;

  margin: 0em 5em;

  width: 65%;
}

.popup-close {
  cursor: pointer;

  float: right;

  font-size: 30px;

  margin: -13px 10px;
}

/* Overlay styles */

.Popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
