.OT_subscriber {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 1000001 !important;
}

.OT_publisher {
  position: absolute !important;
  bottom: 5px !important;
  left: 5px !important;
  z-index: 1000002 !important;
  border: 2px solid white !important;
  border-radius: 2px !important;

  @media (max-width: 424px) {
    width: 260px !important;
    height: 170px !important;
  }

  @media (min-width: 425px) {
    width: 360px !important;
    height: 240px !important;
  }
}
